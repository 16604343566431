import { Card, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const Widget = (props) => {
    return (

        <Card key={props.bg} bg={props.bg} >

            <Card.Body>
                <Row>
                    <Col classname="widgeticon" md={3}>
                        <Link to={props.link}> {props.icon}</Link>
                    </Col>

                    <Col md={9}>
                        <Card.Title className="d-flex justify-content-end widgettitle">{props.title}</Card.Title>
                        <Card.Text >
                            <span className="d-flex justify-content-end counter widgettitle" >  {props.value}</span>

                        </Card.Text>
                    </Col>
                </Row>

            </Card.Body>
            {/* <Card.Footer>
                <Button variant="dark" >{props.linktext}</Button>
            </Card.Footer> */}
        </Card>

    );
}

export default Widget;




